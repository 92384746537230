import { PropsWithChildren, ReactNode } from 'react';
import { Card, CardContent } from './card';
import { AlertCircleIcon } from 'lucide-react';
import { SymbolIcon } from '@radix-ui/react-icons';
import { cn } from 'utils/utils';

type SuspenseProps = {
  isFetching: boolean;
  isError: boolean;
};
function Loader({ children, isFetching, isError }: PropsWithChildren<SuspenseProps>): ReactNode {
  const modalClassName =
    'flex items-center gap-4 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-950 p-4 rounded-lg shadow-lg z-50 border border-gray-600 animate-pulse';
  return (
    <Card className='h-full rounded-none'>
      <CardContent className='h-full relative'>
        {isFetching && (
          <div className={modalClassName}>
            <SymbolIcon className='w-5 h-5 animate-spin' />
            <span className='text-lg'>Loading posts...</span>
          </div>
        )}

        {isError && (
          <div className={cn(modalClassName, 'text-red-500 font-2xl')}>
            <AlertCircleIcon className='w-5 h-5' />
            <span className='text-lg'>Error Loading</span>
          </div>
        )}
        {children}
      </CardContent>
    </Card>
  );
}

export default Loader;
